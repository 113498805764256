import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import api, { Dish, DishCreate, Dishes, DishFilter, DishOrder, DishUpdate } from "@s6e/spicify-api-sdk-js";
import { extractErrors } from "store/common";

interface DishesParams {
	workspaceId: number;
	filter?: DishFilter;
	sorting?: DishOrder;
}

interface DishParams {
	workspaceId: number;
	dishId: number;
}

interface CreateDishParams {
	workspaceId: number,
	data: DishCreate
}

interface UpdateDishParams {
	workspaceId: number,
	dishId: number,
	data: DishUpdate
}

const cacheType: "Dish" = "Dish";
export const dishesApi = createApi({
	reducerPath: 'dishApi',
	baseQuery: fakeBaseQuery(),
	tagTypes: [cacheType],
	endpoints: (builder) => ({
		getDishes: builder.query<Dishes, DishesParams>({
			queryFn: async (params: DishesParams) => {
				try {
					const { workspaceId, filter, sorting } = params;
					const dishes = await api.dishes.getDishes(workspaceId, filter, sorting)
					return { data: dishes }
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToWorkspace",
						"404": "app.workspaceNotFound"
					});
				}
			},
			providesTags: (result = []) => [
				cacheType,
				...result.map(({ id }) => ({ type: cacheType, id }))
			]
		}),
		getDish: builder.query<Dish, DishParams>({
			queryFn: async (params: DishParams) => {
				try {
					const { workspaceId, dishId } = params;
					const dish = await api.dishes.getDish(workspaceId, dishId);
					return { data: dish }
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToWorkspace",
						"404": "app.workspaceNotFound"
					});
				}
			},
			providesTags: (result) => result ? [{ type: cacheType, id: result.id }] : [],
		}),
		createDish: builder.mutation<Dish, CreateDishParams>({
			queryFn: async (params: CreateDishParams) => {
				try {
					const { workspaceId, data } = params;
					const dish = await api.dishes.createDish(workspaceId, data);
					return { data: dish }
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToWorkspace",
						"406": "app.dishLimitReached",
						"409": "app.nonUniqueDishName"
					});
				}
			},
			invalidatesTags: [{ type: cacheType }]
		}),
		updateDish: builder.mutation<Dish, UpdateDishParams>({
			queryFn: async (params: UpdateDishParams) => {
				try {
					const { workspaceId, dishId, data } = params;
					const dish = await api.dishes.updateDish(workspaceId, dishId, data);
					return { data: dish };
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToDish",
						"404": "app.dishNotFound",
						"409": "app.nonUniqueDishName",
					});
				}
			},
			invalidatesTags: (result) => result ? [
				cacheType,
				{ type: cacheType, id: result.id }
			] : []

		}),
		deleteDish: builder.mutation<number, DishParams>({
			queryFn: async (params: DishParams) => {
				const { workspaceId, dishId } = params;
				try {
					await api.dishes.deleteDish(workspaceId, dishId);
					return { data: dishId };
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToDish",
						"404": "app.dishNotFound",
					});
				}
			},
			invalidatesTags: (result) => result ? [
				cacheType,
				{ type: cacheType, id: result }
			] : []
		}),
		setUseDish: builder.mutation<number, DishParams>({
			queryFn: async (params: DishParams) => {
				const { workspaceId, dishId } = params;
				try {
					await api.dishes.setUseDish(workspaceId, dishId);
					return { data: dishId };
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToDish",
						"404": "app.dishNotFound",
					});
				}
			},
			invalidatesTags: [{ type: cacheType }]
		}),
		setDropDish: builder.mutation<number, DishParams>({
			queryFn: async (params: DishParams) => {
				const { workspaceId, dishId } = params;
				try {
					await api.dishes.setDropDish(workspaceId, dishId);
					return { data: dishId };
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToDish",
						"404": "app.dishNotFound",
					});
				}
			},
			invalidatesTags: [{ type: cacheType }]
		}),
		setDishFavorite: builder.mutation<number, DishParams>({
			queryFn: async (params: DishParams) => {
				try {
					const { workspaceId, dishId } = params;
					await api.dishes.setFavoriteDish(workspaceId, dishId);
					return { data: dishId };
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToDish",
						"404": "app.dishNotFound",
					});
				}
			},
			invalidatesTags: [{ type: cacheType }]
		}),
		unsetDishFavorite: builder.mutation<number, DishParams>({
			queryFn: async (params: DishParams) => {
				try {
					const { workspaceId, dishId } = params;
					await api.dishes.unsetFavoriteDish(workspaceId, dishId);
					return { data: dishId };
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToDish",
						"404": "app.dishNotFound",
					});
				}
			},
			invalidatesTags: [{ type: cacheType }]
		}),
	}),
});

export const {
	useGetDishesQuery,
	useGetDishQuery,
	useCreateDishMutation,
	useUpdateDishMutation,
	useDeleteDishMutation,
	useSetUseDishMutation,
	useSetDropDishMutation,
	useSetDishFavoriteMutation,
	useUnsetDishFavoriteMutation
} = dishesApi
