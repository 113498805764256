import { Tag, Tags } from "@s6e/spicify-api-sdk-js";

export type IndexedTag = Tag & {
	index: number;
};

export type TagGroup = {
	groupName: string;
	values: IndexedTag[];
};
export type TagGroups = TagGroup[];

export const tagsToFormControls = (tags: Tags): TagGroups => {
	const groups: TagGroups = [];
	let group: TagGroup | undefined = undefined
	let sorted = tags.map(t => t);
	sorted.sort((a: Tag, b: Tag) => a.group.localeCompare(b.group) || a.name.localeCompare(b.name))

	sorted.forEach((tag, index) => {
		const indexedTag = { 
			...tag,
			index
		};
		if (group?.groupName === tag.group) {
			const groupIndex = groups.length - 1;

			const updatedGroup = {
				groupName: group.groupName,
				values: [
					...group.values,
					indexedTag
				]
			};
			groups[groupIndex] = updatedGroup;
		} else {
			groups.push({
				groupName: tag.group,
				values: [ indexedTag ]
			});
		}
	});
	return groups;
};