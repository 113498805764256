import { FunctionComponent, ReactNode } from "react";
import {
	List,
	ListItemText,
	ListItemIcon,
	ListItemButton,
	ListItem,
	Tooltip
} from "@mui/material";
import { NavLink } from "react-router-dom";

type SideNavigationItem = {
    icon: ReactNode;
    href: string;
    text: string;
};

type SideNavigationItems = SideNavigationItem[];

type SideNavigationProps = {
	listItems: SideNavigationItems;
	shrink?: boolean;
};

const SideNavigation: FunctionComponent<SideNavigationProps> = ({
	listItems,
	shrink
}) => {

	const width = !!shrink ? "60px" : "250px";
	const placement = shrink ? "right-end" : "bottom-end";
	return (
		<List
			sx={{
				pt: 1,
				paddingBottom: 0,
				width: {width},
			}}
		>
			{listItems.map(({ text, icon, href }) => (
				<Tooltip title={text} placement={placement}>
					<ListItem key={text} disablePadding>
						<ListItemButton		
							component={NavLink}
							to={href}
						>
							<ListItemIcon sx={{ my: 0.5 }}>{icon}</ListItemIcon>
							{!shrink && <ListItemText primary={text} />}
						</ListItemButton>
					</ListItem>
				</Tooltip>
			))}
		</List>
	);
};

export default SideNavigation;
