import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentPage from "components/common/ContentPage/ContentPage";
import { Dish } from "@s6e/spicify-api-sdk-js";
import {
    selectFilter,
    selectSorting
} from "store/reducers/dishes/dishesReducer";
import DishList from "components/common/DishList/DishList";
import { pages } from "pages";
import { injectIntl, WrappedComponentProps } from "react-intl";
import {
    useDeleteDishMutation,
    useGetDishesQuery, useSetDishFavoriteMutation, useSetDropDishMutation,
    useSetUseDishMutation, useUnsetDishFavoriteMutation
} from "../../../../../store/apis/dish";
import { errorString } from "helpers/api";
import { useAppSelector } from "hooks";

type DishesPageProps = {
	workspaceId: number;
};

const DishesPage: FunctionComponent<DishesPageProps & WrappedComponentProps> = ({ intl, workspaceId }) => {
	const navigate = useNavigate();

    const filter = useAppSelector(selectFilter);
    const sorting = useAppSelector(selectSorting);

    const { data: dishes, isLoading: isLoadingDishes, error: dishesError }  = useGetDishesQuery({
        workspaceId,
        filter,
        sorting
    });

    const [ deleteDish] = useDeleteDishMutation();
    const [ setUseDish, { isSuccess: dishUsed, originalArgs: useDishParams }] = useSetUseDishMutation();
    const [ setDropDish] = useSetDropDishMutation();
    const [ setDishFavorite] = useSetDishFavoriteMutation();
    const [ unsetDishFavorite] = useUnsetDishFavoriteMutation();

    const onDeleteDishClick = async (dish: Dish) => {
        deleteDish({
            workspaceId,
            dishId: dish.id
        });
    }

    const onUseDishClick = async (dish: Dish) => {
        setUseDish({ workspaceId, dishId: dish.id });
    }
    useEffect(() => {
        if (dishUsed && useDishParams) {
            navigate(pages.workspace.dishView.url(workspaceId, useDishParams.dishId));
        }
    }, [ dishUsed, useDishParams, navigate, workspaceId ]);

    const onDropDishClick = async (dish: Dish) => {
        setDropDish({ workspaceId, dishId: dish.id })
    };

    const onEditDishClick = async (dish: Dish) => {
        navigate(pages.workspace.dishEdit.url(workspaceId, dish.id));
    }

    const onToggleFavoriteClick = async (dish: Dish) => {
        if (dish.favorite) {
			unsetDishFavorite({ workspaceId, dishId: dish.id });
		} else {
			setDishFavorite({ workspaceId, dishId: dish.id });
		}
    }
    
    const viewDish = async (dish: Dish) => {
        navigate(pages.workspace.dishView.url(workspaceId, dish.id));
    }

	return (
        <ContentPage
            loading={isLoadingDishes}
            title={intl.formatMessage({ id: "app.dishes" })}
            filterLink="filter"
            sortingLink="sorting"
            error={errorString(intl, dishesError)}
			button={{ text: intl.formatMessage({ id: "app.addDish" }), href: pages.workspace.dishCreate.url(workspaceId)}}
        >
            {dishes && (
                <DishList
                    dishes={dishes}
                    onDishDeleteClick={onDeleteDishClick}
                    onDishUseClick={onUseDishClick}
                    onDishDropClick={onDropDishClick}
                    onDishEditClick={onEditDishClick}
                    onDishFavoriteClick={onToggleFavoriteClick}
                    onDishNameClick={viewDish}
                />)}
        </ContentPage>
	);
};

export default injectIntl(DishesPage);
