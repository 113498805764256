import { Route, Routes, useParams } from "react-router-dom";
import ProtectedRoute from "components/common/ProtectedRoute/ProtectedRoute";
import DishPage from "components/composed/DishesPage/DishPage/DishPage";
import TagsPage from "components/composed/TagPage/TagsPage";
import TagPage from "components/composed/TagPage/TagPage/TagPage";
import WorkspaceEditPage from "components/composed/WorkspacesPage/WorkspaceForm/WorkspaceEditPage";
import DishView from "components/composed/DishesPage/DishView/DishView";
import SharePage from "components/composed/SharePage/SharePage";
import WorkspacePage from "components/composed/WorkspacesPage/WorkspacePage/WorkspacePage";
import WorkspaceLayout from "./WorkspacePage/WorkspaceLayout";
import { pages } from "pages";
import ContentError from "components/common/ContentPage/ContentError";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { FunctionComponent } from "react";
import { getNumberParam } from "helpers/views";
import DishFilterView from "../DishesPage/DishFilterView";
import DishSortingView from "../DishesPage/DishSortingView";
import GeneratorFilterPage from "../GeneratorPage/GeneratorForm/GeneratorFilterPage";


type WorkspaceLayoutParams = {
	workspaceId?: string;
};

const Workspace: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
    const params = useParams<WorkspaceLayoutParams>();
	const [ workspaceId, paramError ] = getNumberParam(params.workspaceId, "workspaceId", intl);

	if (paramError || !workspaceId) {
        return <ContentError error={paramError} />
    }

    return (
        <>
			{workspaceId && (
                <WorkspaceLayout workspaceId={workspaceId} intl={intl} >
                    <Routes>
                        <Route path={pages.workspace.edit.pattern} element={
                            <ProtectedRoute>
                                <WorkspaceEditPage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.share.pattern} element={
                            <ProtectedRoute>
                                <SharePage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.tags.pattern} element={
                            <ProtectedRoute>
                                <TagsPage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.tagCreate.pattern} element={
                            <ProtectedRoute>
                                <TagPage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.tagEdit.pattern} element={
                            <ProtectedRoute>
                                <TagPage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.dishCreate.pattern} element={
                            <ProtectedRoute>
                                <DishPage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.dishFilter.pattern} element={
                            <ProtectedRoute>
                                <DishFilterView workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.dishSorting.pattern} element={
                            <ProtectedRoute>
                                <DishSortingView workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.dishEdit.pattern} element={
                            <ProtectedRoute>
                                <DishPage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.dishView.pattern} element={
                            <ProtectedRoute>
                                <DishView workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path={pages.workspace.suggestedSettings.pattern} element={
                            <ProtectedRoute>
                                <GeneratorFilterPage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                        <Route path="*" element={
                            <ProtectedRoute>
                                <WorkspacePage workspaceId={workspaceId} />
                            </ProtectedRoute>
                        } />
                    </Routes>
                </WorkspaceLayout>)}
        </>
	);
};

export default injectIntl(Workspace);