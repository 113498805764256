import { Tag, TagCreate, Tags, TagUpdate } from "@s6e/spicify-api-sdk-js";
import api from "@s6e/spicify-api-sdk-js";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { extractErrors } from "store/common";

interface TagParams {
	workspaceId: number;
	tagId: number;
}

interface CreateTagParams {
	workspaceId: number,
	data: TagCreate
}

interface UpdateTagParams {
	workspaceId: number,
	tagId: number,
	data: TagUpdate
}

const cacheType: "Tag" = "Tag";
export const tagApi = createApi({
	reducerPath: 'tagApi',
	baseQuery: fakeBaseQuery(),
	tagTypes: [cacheType],
	endpoints: (builder) => ({
		getTags: builder.query<Tags, number>({
			queryFn: async (workspaceId) => {
				try {
					const tags = await api.tags.getTags(workspaceId);
					return { data: tags }
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToTag",
						"404": "app.workspaceNotFound",
					});
				}
			},
			providesTags: (result = []) => [
				cacheType,
				...result.map(({ id }) => ({ type: cacheType, id }))
			]
		}),
		getTag: builder.query<Tag, TagParams>({
			queryFn: async (params) => {
				try {
					const { workspaceId, tagId } = params;
					const tag = await api.tags.getTag(workspaceId, tagId);
					return { data: tag }
				} catch (error) {
					return extractErrors(error);
				}
			},
			providesTags: (result) => result ? [{ type: cacheType, id: result.id }] : [],
		}),
		createTag: builder.mutation<Tag, CreateTagParams>({
			queryFn: async (params: CreateTagParams) => {
				try {
					const { workspaceId, data } = params;
					const tag = await api.tags.createTag(workspaceId, data);
					return { data: tag }
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToWorkspace",
						"406": "app.tagLimitReached",
						"409": "app.nonUniqueTagName"
					});
				}
			},
			invalidatesTags: [{ type: cacheType }]
		}),
		updateTag: builder.mutation<Tag, UpdateTagParams>({
			queryFn: async (params: UpdateTagParams) => {
				try {
					const { workspaceId, tagId, data } = params;
					const tag = await api.tags.updateTag(workspaceId, tagId, data);
					return { data: tag };
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToWorkspace",
						"404": "app.tagNotFound",
						"409": "app.nonUniqueTagName"
					});
				}
			},
			invalidatesTags: (result) => result ? [
				cacheType,
				{ type: cacheType, id: result.id }
			] : []
		}),
		deleteTag: builder.mutation<number, TagParams>({
			queryFn: async (params: TagParams) => {
				const { workspaceId, tagId } = params;
				try {
					await api.tags.deleteTag(workspaceId, tagId);
					return { data: tagId };
				} catch (error) {
					return extractErrors(error, {
						"403": "app.noAccessToWorkspace",
						"404": "app.tagNotFound"
					});
				}
			},
			invalidatesTags: (result) => result ? [
				cacheType,
				{ type: cacheType, id: result }
			] : []
		}),
	}),
});

export const {
	useGetTagsQuery,
	useGetTagQuery,
	useCreateTagMutation,
	useUpdateTagMutation,
	useDeleteTagMutation,
} = tagApi