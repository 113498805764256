export function extractErrors(error: any, statusMap?: Record<string, string>) {
    let status = undefined;
    if (error.status !== undefined) {
        status = String(error.status);
    }

    if (error.response?.status !== undefined) {
        status = String(error.response.status);
    }

    if (statusMap !== undefined && status !== undefined && status in statusMap) {
        return { error: statusMap[status]}
    }

    return { error: "app.somethingWentWrong" }
}