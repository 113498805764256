import { useState, useEffect, FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Typography,
	List,
	ListItem,
	ListItemText,
	IconButton
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import {
	injectIntl,
	WrappedComponentProps
} from "react-intl";
import { Workspace } from "@s6e/spicify-api-sdk-js";
import { pages } from "pages";
import WorkspaceDeleteDialog from "./WorkspaceDeleteDialog";
import { useDeleteWorkspaceMutation, useGetWorkspacesQuery } from "../../../store/apis/workspace";
import { errorString } from "../../../helpers/api";
import ContentPage from "components/common/ContentPage/ContentPage";


const WorkspacesPage: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
	const navigate = useNavigate();

	const [isDialogOpened, setDialogOpened] = useState(false);
	const [workspaceToDelete, setWorkspaceToDelete] =
		useState<Workspace | null>(null);

	const { data: workspaces, isLoading, error } = useGetWorkspacesQuery();
	const [
		deleteWorkspace,
		{
			isSuccess: isWorkspaceDeleteSuccess,
			isError: isWorkspaceDeleteError,
			error: workspaceDeleteError,
			isLoading: isWorkspaceDeleteInProgress
		}
	] = useDeleteWorkspaceMutation()


	const onWorkspaceClick = async (workspaceId: number) => {
		navigate(pages.workspace.suggested.url(workspaceId));
	};

	const renderListItems = () => {
		if (workspaces) {
			return (
				<>
					{workspaces.map((workspace: Workspace) => {
						return (
							<ListItem key={workspace.id}
								onClick={() => onWorkspaceClick(workspace.id)}
								sx={{ cursor: "pointer" }}
								divider
								secondaryAction={!workspace.personal ? (
									<>
										<IconButton
											component={Link}
											to={pages.workspace.share.url(workspace.id)}
										>
										<ShareIcon color="primary" />
											</IconButton>
										<IconButton
											component={Link}
											to={pages.workspace.edit.url(workspace.id)}
										>
											<EditIcon color="primary" />
										</IconButton>
										<IconButton
											onClick={() => {
												setWorkspaceToDelete(workspace);
												setDialogOpened(true);
											}}
										>
											<DeleteIcon color="secondary" />
										</IconButton>
									</>
								) : undefined}
							>
								<ListItemText
									primary={workspace.name}
									style={{
										cursor: "pointer",
										flex: "inherit",
										wordBreak: "break-word"
									}}
								/>
							</ListItem>
						);
					})}
				</>
			);
		} else {
			return (
				<Typography variant="h5">
					{intl.formatMessage({ id: "app.emptyWorkspaces" })}
				</Typography>
			);
		}
	};

	const onDeleteWorkspaceClick = async () => {
		if (workspaceToDelete) {
			deleteWorkspace(workspaceToDelete.id);
		}
	};

	useEffect(() => {
		if (isWorkspaceDeleteSuccess || isWorkspaceDeleteError) {
			closeDialog();
		}
	}, [ isWorkspaceDeleteSuccess, isWorkspaceDeleteError ]);

	useEffect(() => {
		if (workspaceDeleteError) {
			toast.error(errorString(intl, workspaceDeleteError));
		}
	}, [ workspaceDeleteError, intl ]);

	const closeDialog = () => {
		setDialogOpened(false);
	};

	return (
		<ContentPage
			loading={isLoading}
			error={errorString(intl, error)}
			title={intl.formatMessage({ id: "app.workspaces" })}
			button={{ text: intl.formatMessage({ id: "app.create" }), href: pages.workspaceCreate.url()}}
		>
			<List sx={{ flexGrow: 4 }}>{renderListItems()}</List>
			{workspaceToDelete && 
				(<WorkspaceDeleteDialog 
					isOpen={isDialogOpened}
					deleteInProgress={isWorkspaceDeleteInProgress}
					onClose={closeDialog}
					workspace={workspaceToDelete}
					onDelete={onDeleteWorkspaceClick}
				/>)}
		</ContentPage>
	);
};

export default injectIntl(WorkspacesPage);
