import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Tag } from "@s6e/spicify-api-sdk-js";
import DeleteDialog from "components/common/DeleteDialog";

interface TagDeleteDialogProps {
	tag: Tag;
	isOpen: boolean;
	deleteInProgress: boolean;
	onClose: () => void;
	onDelete: () => void;
}

const TagDeleteDialog: FunctionComponent<TagDeleteDialogProps> = ({
	tag,
	isOpen,
	deleteInProgress,
	onClose,
	onDelete
}) => {
	return (
		<DeleteDialog
			open={isOpen}
			onClose={onClose}
			inProgress={deleteInProgress}
			onDelete={onDelete}
		>
			<FormattedMessage
					id="app.confirmTagDelete"
					values={{
						tagName: <strong>"{tag.name}"</strong>
					}}
				/>
		</DeleteDialog>
	);
};

export default TagDeleteDialog;
