import { FunctionComponent, ReactNode } from "react";
import { useAppSelector } from "hooks";
import LabelIcon from "@mui/icons-material/Label";
import FoodBankIcon from '@mui/icons-material/FoodBank';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

import SideNavigation from "./Navigation/SideNavigation";
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { pages } from "pages";
import { WrappedComponentProps } from "react-intl";
import { NavLink } from "react-router-dom";
import { AutoAwesome } from "@mui/icons-material";

type LayoutProps = {
	workspaceId: number;
	children: ReactNode;
};

const WorkspaceLayout: FunctionComponent<LayoutProps & WrappedComponentProps> = ({
	intl, workspaceId, children
}) => {
	const isNavbarOpen = useAppSelector((state) => state.layout.isNavbarOpen);
	const dataForNavigation = workspaceId ? [
		{
			icon: <AutoAwesome style={{ color: "#000" }} />,
			href: pages.workspace.suggested.url(workspaceId),
			text: intl.formatMessage({ id: "app.suggested" })
		},
		{
			icon: <FoodBankIcon style={{ color: "#000" }} />,
			href: pages.workspace.dishes.url(workspaceId),
			text: intl.formatMessage({ id: "app.dishes" })
		},
		{
			icon: <LabelIcon style={{ color: "#000" }} />,
			href: pages.workspace.tags.url(workspaceId),
			text: intl.formatMessage({ id: "app.tags" })
		},
		{
			icon: <WorkspacesIcon style={{ color: "#000" }} />,
			href: pages.workspaces.url(),
			text: intl.formatMessage({ id: "app.workspaces" })
		}
	] : [];
	
	return (
		<Box component="div" className="Spicify-WorkspaceLayout-root" sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
			<Box component="div" className="Spicify-WorkspaceLayout-main" sx={{ display: "flex", flexDirection: "row", flexGrow: 1, overflow: "scroll" }}>
				<Box component="div" className="Spicify-WorkspaceLayout-side" sx={{
					margin: 0,
					display: { xs: 'none', md: 'flex' },
					flex: 'none',
					borderRight: "solid 1px rgba(0, 0, 0, 0.12)"
				}}>
					<SideNavigation shrink={!isNavbarOpen} listItems={dataForNavigation} />
				</Box>
				<Box component="div" className="Spicify-WorkspaceLayout-content" sx={{
					flexGrow: 1,
					display: "flex"
				}}>
					{children}
				</Box>
			</Box>
			<BottomNavigation showLabels className="Spicify-WorkspaceLayout-bottom" sx={{
				display: { xs: 'flex', md: 'none' },
			}}>
				{dataForNavigation.map(({ text, icon, href }) => (
					<BottomNavigationAction
						key={text}
						component={NavLink}
						to={href}
						label={text}
						icon={icon}
					/>
				))}
			</BottomNavigation>
		</Box>
	);
};

export default WorkspaceLayout;
