import { languageStorageKey } from "consts";
import English from "lang/en.json";
import Polish from "lang/pl.json";

export interface Translation {
	locale: string;
	language: any;
	short: string;
	intlLocale: string;
}

export const translations: Translation[] = [
	{ locale: "en-GB", language: English, short: "en", intlLocale: "en" },
	{ locale: "en-US", language: English, short: "en", intlLocale: "en" },
	{ locale: "pl", language: Polish, short: "pl", intlLocale: "pl" },
	{ locale: "pl-PL", language: Polish, short: "pl", intlLocale: "pl" }
];

export const defineLanguage = (): Translation => {
	let locale = localStorage.getItem(languageStorageKey);
	if (!locale) {
		locale = navigator.language;
		console.log(`Locale not set. Selecting based on browser's preferrence: ${locale}`)
	}

	let translation = translations.find((item) => item.locale === locale)
	if (!translation) {
		console.log(`Translation for locale '${locale}' not found. Selecting default: ${translations[0].intlLocale}`)
	}
	return translation || translations[0];
};
