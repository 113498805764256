import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
	TextField,
	Button,
	FormControl,
	FormLabel,
	FormGroup,
	FormControlLabel,
	Checkbox,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Autocomplete,
	Stack
} from "@mui/material";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Dish, Tag, Tags } from "@s6e/spicify-api-sdk-js";
import SubmitButton from "components/common/SubmitButton";
import { errorString } from "helpers/api";
import ContentError from "components/common/ContentPage/ContentError";

type DishFormSubmit = (payload: FieldValues) => void;
export type DishPageProps = {
	dish?: Dish,
	onSubmit: DishFormSubmit,
	tags?: Tags,
	tagsLoading: boolean,
	isTagsError: boolean,
	tagsError: unknown,
	error: unknown,
	closeLink: string,
	processing: boolean
}

const DishPage: FunctionComponent<DishPageProps & WrappedComponentProps> = ({
	dish,
	onSubmit,
	tags,
	tagsLoading,
	isTagsError,
	tagsError,
	error,
	closeLink,
	processing,
	intl
}) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors }
	} = useForm<Dish>({
		shouldUnregister: true,
		defaultValues: dish
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={2}>
				<TextField
					{...register("name", {
						required: intl.formatMessage({ id: "app.dishNameIsRequired" })
					})}
					fullWidth
					label={intl.formatMessage({ id: "app.name" })}
					helperText={errors.name?.message?.toString() || ''}
					error={!!errors.name}
				/>
				<FormControl component="fieldset" fullWidth error={!!errors.spring || !!errors.summer || !!errors.autumn || !!errors.winter}>
					<FormLabel
						sx={{ textAlign: "center" }}
					>
						{intl.formatMessage({ id: "app.season" })}
					</FormLabel>
					<FormGroup
						aria-label="position"
						row
						sx={{ margin: "0 auto" }}
					>
						<FormControlLabel
							control={
								<Controller
									name="spring"
									control={control}
									render={({ field: {value, ...props} }) => (
										<Checkbox
											checked={value}
											{...props}
										/>
									)}
								/>
							}
							label={intl.formatMessage({ id: "app.spring" })}
						/>
						<FormControlLabel
							control={
								<Controller
									name="summer"
									control={control}
									render={({ field: {value, ...props} }) => (
										<Checkbox
											checked={value}
											{...props}
										/>
									)}
								/>
							}
							label={intl.formatMessage({ id: "app.summer" })}
						/>
						<FormControlLabel
							control={
								<Controller
									name="autumn"
									control={control}
									render={({ field: {value, ...props} }) => (
										<Checkbox
											checked={value}
											{...props}
										/>
									)}
								/>
							}
							label={intl.formatMessage({ id: "app.autumn" })}
						/>
						<FormControlLabel
							control={
								<Controller
									name="winter"
									control={control}
									render={({ field: {value, ...props} }) => (
										<Checkbox
											checked={value}
											{...props}
										/>
									)}
								/>
							}
							label={intl.formatMessage({ id: "app.winter" })}
						/>
					</FormGroup>
					<FormHelperText>{intl.formatMessage({ id: "app.seasonHelperText" })}</FormHelperText>
				</FormControl>
				<FormControl
					fullWidth
					error={!!errors.time_effort}
				>
					<InputLabel>
						{intl.formatMessage({ id: "app.timeEffort" })}
					</InputLabel>
					<Controller
						render={({ field }) => (
							<Select
								label={intl.formatMessage({
									id: "app.timeEffort"
								})}
								sx={{ textAlign: "left" }}
								{...field}
							>
								<MenuItem value={0}>
									{intl.formatMessage({
										id: "app.short"
									})}
								</MenuItem>
								<MenuItem value={1}>
									{intl.formatMessage({
										id: "app.medium"
									})}
								</MenuItem>
								<MenuItem value={2}>
									{intl.formatMessage({ id: "app.long" })}
								</MenuItem>
							</Select>
						)}
						name="time_effort"
						control={control}
						rules={{ required: true }}
					/>
					<FormHelperText>
						{errors.time_effort &&
							intl.formatMessage({ id: "app.emptyField" })}
					</FormHelperText>
				</FormControl>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.recipe" })}
					{...register("recipe")}
					error={!!errors.recipe}
					helperText={!!errors.recipe ? errors.recipe?.message?.toString() : intl.formatMessage({ id: "app.recipeHelperText"})}
				/>
				<TextField
					fullWidth
					label={intl.formatMessage({ id: "app.kilocalories" })}
					{...register("kilocalories")}
					type="number"
					slotProps={{
						htmlInput: { min: "0", max: "10000", step: "1" }
					}}
					helperText={errors.kilocalories?.message?.toString() || ''}
					error={!!errors.kilocalories}
				/>
				<FormControl component="fieldset" fullWidth>
					<FormLabel sx={{ textAlign: "center" }}>
						{intl.formatMessage({ id: "app.tags" })}
					</FormLabel>
					<Controller
						name="tags"
						control={control}
						render={({ field: { value, onChange, ...props} }) => (
							<Autocomplete
								multiple
								value={value}
								onChange={(_event, selectedOptions) => {
									onChange(selectedOptions);
								}}
								{...props}
								options={tags || []}
								loading={tagsLoading}
								noOptionsText={intl.formatMessage({
									id: "app.noOptions"
								})}
								getOptionLabel={(option: Tag) => option.name}
								isOptionEqualToValue={(option, value) =>
									option.id === value.id
								}
								groupBy={(option) => option.group}
								renderInput={(params) => (
									<TextField
										{...params}
										label={intl.formatMessage({
											id: "app.chooseTag"
										})}
										error={isTagsError}
										helperText={isTagsError ? errorString(intl, tagsError) : intl.formatMessage({ id: "app.pickOneOrMoreTags" })}
										fullWidth
									/>
								)}
							/>							
						)}
					/>
				</FormControl>
				<ContentError error={errorString(intl, error)} />
				<Stack direction="row" spacing={2} justifyContent="flex-end">
					<Button
						color="secondary"
						component={Link}
						to={closeLink}
						disabled={processing}
					>
						{intl.formatMessage({ id: "app.cancel" })}
					</Button>
					<SubmitButton isLoading={processing}>
						{intl.formatMessage({ id: "app.save" })}
					</SubmitButton>
				</Stack>
			</Stack>
		</form>
	);
};

export default injectIntl(DishPage);
